<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Integrations for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Advanced integration options to configure and automate interactions with your event.
        </p>

        <v-alert v-if="!eventUtil.isProPlan()" type="info" outlined>
          <p><router-link class="info--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> to be able to embed results or connect with our API.</p>
        </v-alert>
      </v-card-text>


      <v-card-title class="subheader">Result embedding</v-card-title>
      <v-card-text>
        <p>Select the race you'd like to embed:</p>
    <v-select 
      :items="races || []" 
      item-text="name" 
      item-value="id" 
      v-model="raceId" 
      hide-details
      solo
      rounded
      style="max-width:250px" 
      class="mr-4 mt-0 pt-0 d-inline-block" 
      />
        <p class="mt-8 mb-0">Copy and paste HTML code below and include it on your website:</p>
          <pre v-if="eventUtil.isProPlan()">&lt;iframe  
            width="100%" 
            height="600" 
            style="border:0;" 
            loading="lazy" 
            allowfullscreen 
            src="{{tenant.homeUrl}}events/{{event.id}}/{{raceId}}?view=embed"
            &gt;
&lt;/iframe&gt;</pre>        
        <code v-else>Upgrade to PRO to view the embed HTML.</code>
      </v-card-text>
      <v-card-title class="subheader">API access</v-card-title>
      <v-card-text>      
        <p>
          Use our API to integrate directly with the platform. This allows you to automatically sync registration data from your website with our platform. 
          <a href="/api-docs" target="_blank">View API docs</a>.
        </p>
        <p>
          Use these API key(s) to connect to this event using our API. Keep these API keys secret and never expose them publicly.
        </p>
        <code v-if="eventUtil.isProPlan()">{{ event.api_keys.join(', ') }}</code>
        <code v-else>Upgrade to PRO to access the API.</code>
      </v-card-text>


      <v-card-title class="subheader">External Integrations</v-card-title>
      <v-row class="mx-2"> 
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Strava Client</v-card-title>
            <v-card-text>Configure your own Strava client to prevent API limit issues.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!eventUtil.isProPlan()" :to="{name:'eventmanagerOAuthClient', params: {id: event.id, provider: 'strava'}}">Configure</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Webhooks</v-card-title>
            <v-card-text>Configure your own webhooks to be triggered on certain events.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!eventUtil.isProPlan()" @click="webhooksDialogVisible = true;">Configure</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="tenant.id === 'eventscom'" cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Events.com</v-card-title>
            <v-card-text v-if="isConnectedWithEventsCom">Configure Events.com connection.</v-card-text>
            <v-card-text v-else>Connect with an Events.com event.</v-card-text>
            <v-card-actions>
              <v-btn v-if="isConnectedWithEventsCom" color="primary" text :disabled="!eventUtil.isProPlan()" :to="{name:'eventmanagerIntegrationsEventsCom', params: {id: event.id}}">Configure</v-btn>
              <v-btn v-else-if="profile" color="primary" text :disabled="!eventUtil.isProPlan()" :href="oauthProviders.eventsComOrgConnectUrl({eventId: event.id, mode: 'eventmanager', profileId: profile.id, tenant: tenant.id })">Connect</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="tenant.id === 'cofi'" cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Slack</v-card-title>
            <v-card-text v-if="isConnectedWithSlack">Configure Slack connection.</v-card-text>
            <v-card-text v-else>Connect with a Slack workspace.</v-card-text>
            <v-card-actions>
              <v-btn v-if="isConnectedWithSlack" color="primary" text :disabled="!eventUtil.isFreePlan() && !eventUtil.isProPlan()" :to="{name:'eventmanagerIntegrationsSlack', params: {id: event.id}}">Configure</v-btn>
              <v-btn v-else-if="profile" color="primary" text :disabled="!eventUtil.isFreePlan() && !eventUtil.isProPlan()" :href="oauthProviders.slackConnectUrl({eventId: event.id, mode: 'eventmanager', profileId: profile.id, tenant: tenant.id })">Connect</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="tenant.id === 'cofi'" cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Microsoft Teams</v-card-title>
            <v-card-text v-if="isConnectedWithTeams">View Teams status.</v-card-text>
            <v-card-text v-else>Connect with Microsoft Teams.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!eventUtil.isFreePlan() && !eventUtil.isProPlan()" :to="{name:'eventmanagerIntegrationsTeams', params: {id: event.id}}">Details</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="tenant.isDefault" cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>App</v-card-title>
            <v-card-text>Enable or configure the app integration.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :to="{name:'eventmanagerIntegrationsApp', params: {id: event.id}}">Details</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>    
    </v-card>

    <v-dialog v-if="webhooks" v-model="webhooksDialogVisible" max-width="600px">
      <v-card>
        <v-card-title>Configure Webhooks</v-card-title>
        <v-card-text>
          Configure the webhooks for this event. When a specific url is configured a POST will be made whenever that event is triggered.
        </v-card-text>
        <v-card-text>
          <v-text-field 
            v-model="webhooks.participant"
            label="Participant webhook"
            persistent-hint
            hint="Url whenever a participant joins a challenge or updates its settings. Must accept a POST request."
            />
            <br/>
            <strong>Example payload:</strong>
          <pre>{
  "event_id": "your-event-id",
  "code": "42",
  "race_ids": null, // can also be string array: ["5k", "10k"]
  "email": "email@gmail.com", // can also be null
  "join_date":"2021-06-08T09:43:08.3799403+00:00"
}</pre>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="webhooksDialogVisible = false">{{$t('shared.cancel')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="saveWebhooks">{{$t('shared.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>        
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import oauthProviders from '@/util/oauthProviders.js'
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Integrations",
  components: {
    Header,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      tenant: tenant,
      races: null,
      oauthProviders: oauthProviders,
      profile: null,
      raceId: null,
      webhooks: null,
      siteData: siteData,
      webhooksDialogVisible: false,
    };
  },
  async mounted() {
    await this.loadData();
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async loadData() {
      if (!this.event) {
        return;
      }
      this.races = this.event.races;
      this.raceId = this.event.races && this.event.races.length ? this.event.races[0].id : null;
      this.webhooks = this.event.webhooks || {};
    },

    async getProfile() {
      if (this.user) {
        this.profile = (await profileService.get()).data;
      }
    },

    async saveWebhooks() {
      const response = (await eventManagerService.putWebhooks(this.event.id, this.webhooks)).data;
      if (response.status == "OK") {
        this.webhooksDialogVisible = false;
      }
      this.$helpers.toastResponse(this, response, 'Data saved successfully.');
    },

  },
  watch: {
    event(val) {
      this.loadData();
    }
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Integrations`, disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    isDevelopment() {
      return process.env.NODE_ENV == 'development';
    },
    isConnectedWithEventsCom() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'eventscom.org_id');
    },
    isConnectedWithSlack() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'slack.team_id');
    },
    isConnectedWithTeams() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'teams.tenant_id');
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

